@import "../../../scss/variables";
@import "../../../scss/mixin";

section.blog_list_sec {
    background-color: $secondary;

    .content_wrap {
        display: grid;
        grid-template-columns: 0.7fr 2fr;
        gap: 24px;

        @media only screen and (max-width:$desktop) {
            grid-template-columns: 1fr;
            gap: 48px;
        }

        .filter_wrap {
            position: sticky;
            top:100px;
            align-self: flex-start;

            @media only screen and (max-width:$desktop) {
                position: relative;
                top: 0px;
            }

            .search_wrap {
                margin:32px 0px;
                border-bottom: 1px solid $primary;
                padding-bottom:8px;
                display:flex;
                justify-content: space-between;
                align-items: center;
                input {
                    border-style: none;
                    background-color: transparent;
                    width: calc(100% - 60px);
                    @include font('DMSans-Medium', 1.5rem, 24px, 500, $primary);
                }
                svg{
                    font-size:26px;
                }
            }

            .pills_list {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 16px;
                align-self: flex-start;

                @media only screen and (max-width:$desktop) {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    flex-wrap: wrap;
                }

                button {

                    padding: 16px;
                    border: 1px solid $border;
                    border-radius: 32px;
                    width: 100%;
                    background-color: $ternery;
                    transition: all 0.3s ease-in;

                    @media only screen and (max-width:$desktop) {
                        width: auto;
                        min-width: 100px;
                    }

                    &.active,
                    &:hover {
                        border: 1px solid $primary;
                        color: $primary;
                    }

                }
            }

        }

        .blogs_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
            }
            @media only screen and (max-width:$mobile) {
                gap:16px;
            }

          
        }
    }
}