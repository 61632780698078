@import "../../../scss/variables";
@import "../../../scss/mixin";

section.our_team {
    .content_wrap {
        max-width: 1200px;

        h2 {
            margin-bottom: 56px;
            text-align: center;

        }

        .team_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr 1fr;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
            }

            .team_card {
               

                .profile_wrap {
                    min-height: Calc(100% - 84px);
                    img {
                        position:relative;
                       width: 100%;
                        min-height:100%;
                        object-fit: contain;
                        border-radius: 16px;
                        user-select: none;
                        pointer-events: none;
                    }
                }

                .text {
                    padding: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left {
                        p {
                            color: $primary;

                            &.post {
                                @include font('DMSans-Medium', 16px, 20px, 500, $primary);
                                margin-top: 4px;
                            }
                        }
                    }

                    a {
                        transition: all 0.3s ease-in-out;

                        svg {
                            font-size: 32px;
                            color: $grey;
                            transform: scale(1);
                            transition: all 0.3s ease-in-out;

                            @media only screen and (max-width:$tab) {
                                font-size: 26px;
                            }
                        }

                        &:hover {
                            svg {
                                transform: scale(1.1);
                            }
                        }
                    }

                }

                &:hover {
                    .text {
                        .left {
                            p {
                                color: $primary;

                                &.post {
                                    color: $primary;
                                }
                            }
                        }

                        svg {
                            color: $primary
                        }
                    }
                }
            }

        }
    }
}