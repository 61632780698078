@import "../../scss/variables";

.portfolio_card {
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid rgba($grey, 0.5);
    opacity: 1;
    transform: scale(1);
    transition: all 0.6s ease-in;

    &.blure {
        opacity: 0.5;
        transform: scale(0.9);
        cursor: pointer;
    }

    .img_screen {
        width: 100%;
        background-color: $ternery;
            padding: 0px;
        overflow: hidden;
        display:grid;
        place-items: center;
        img {
            width: 100%;   
            object-fit:cover;
        }
    }

    .name_wrap {
        padding: 24px;
        background-color: $offDark;
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap:8px;
        flex-wrap: wrap;
  
        .left{
            width:calc(100% - 80px);
            h3 {
                color: $white;
            }
        }
       
    }

    &:hover {
        .name_wrap {
            background-color: $lightOffDark;
        }
    }
}