@import "../../../../scss/variables";
@import "../../../../scss/mixin";

.offering_pill {
    padding: 32px;
    border: 1px solid $lightOffDark;
    border-radius: 16px;
    margin-top: 32px;
    transform: scale(1);
    background-color: rgba($white, 0.03);
    transition: all 0.3s ease-in-out;

    @media only screen and (max-width:$tab) {
        padding: 32px 24px;
        margin-top: 24px;
    }

    @media only screen and (max-width:$mobile) {
        margin-top: 16px;
        padding: 24px 16px;
    }


    .title_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 56px;
            width: calc(100% - 200px);

            @media only screen and (max-width:$mobile) {
                width: 100%;
                flex-wrap: wrap;
            }

            @media only screen and (max-width:$tab) {
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
                width: calc(100% - 50px);
            }

            h2 {
                @include font('DMSans-Regular', 3.3rem, 1.2, 400, $white);
                margin: 0px;

                @media only screen and (max-width:$laptop) {
                    @include font('DMSans-Regular', 3rem, 1.2, 400, $white);
                }

                @media only screen and (max-width:$tab) {
                    @include font('DMSans-Regular', 2rem, 1.2, 400, $white);
                }

                &.num {
                    @media only screen and (max-width:$tab) {
                        @include font('DMSans-Regular', 3rem, 1.2, 400, $white);
                    }
                }
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;

            p {
                color: $white;

                @media only screen and (max-width:$tab) {
                    display: none;
                }

            }

            button {
                overflow: hidden;
                border-style: none;
                background-color: transparent;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                border: 1px solid $white;
                display: grid;
                place-items: center;
                position: relative;
                transition: all 0.3s ease-in-out;

                .white {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    display: grid;
                    place-items: center;
                    background-color: $white;
                    left: -110%;
                    top: 0px;
                    transition: all 0.3s ease-in-out;
                    z-index: 0;

                }

                svg {
                    color: $white;
                    font-size: 24px;
                    position: relative;
                    z-index: 1;
                    transform: rotate(0deg);
                    transition: all 0.3s ease-in-out;

                }

            }
        }
    }


    &:hover {
        transform: scale(1.04);
    }

    &.active {
        .title_wrap {
            .right {
                button {
                    .white {

                        left: 0px;
                    }

                    svg {
                        color: $primary;
                        transform: rotate(135deg);
                    }
                }
            }
        }
    }

    .bi_grid {
        padding-top: 48px;
        padding-left: 130px;
        transform-origin: top center;

        @media only screen and (max-width:$laptop) {
            padding-left: 80px;
        }

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
            gap: 16px;
            padding-left: 0px;
        }

    }

}