@import "../../scss/variables";

section.loader_sec {
    background-color: $primary;
    width: 100%;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 999999;
    padding-left:0px;
    padding-right:0px;
    height: 100vh;
    display: grid;
    place-items: center;

    .content_wrap {
       display:grid;
       place-items: center;

        img.brand_logo {
            max-width:300px;
            width: 100%;
            object-fit: contain;
            opacity:0;
            animation-name: zoomAnim;
            animation-duration: 1s;
            animation-fill-mode:forwards;
            animation-delay: 0.5s;
        }

  
    }

    .top {
        position: absolute;
        height: 50%;
        width: 100%;
        background-color:$offDark;
        left: 0px;
        top: 0px;
        transform-origin: top center;
        animation-name: swapAnim;
        animation-duration: 1s;
        animation-fill-mode:forwards;
        animation-delay: 0.5s;
    }

    .bottom {
        position: absolute;
        height: 50%;
        width: 100%;
        background-color:$offDark;
        left: 0px;
        bottom: 0px;
        transform-origin:bottom center;
        animation-name: swapAnim;
        animation-duration: 1s;
        animation-fill-mode:forwards;
        animation-delay: 0.5s;

    }

    // video.brand{
    //     width:100%;
    // }
}


@keyframes swapAnim {
    from {
        height:50%;
    } to{
        height:0%;
    }
}

@keyframes zoomAnim {
    from {
        opacity:0;
        transform: scale(0.5);
    } to{
        opacity:1;
        transform: scale(1);
    }
}