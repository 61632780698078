@import "../../../scss/variables";

.bg_wrap {
    background-color: $secondary;
    position: relative;
    z-index: 3;
}

section.project_count {
    background-color: $primary;
    border-radius: 32px 32px 0px 0px;
    overflow: hidden;
    position: relative;
    z-index: 3;


video {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
}

.content_wrap {
    position: relative;
    z-index: 4;

    .text {
        position: relative;
        width: 100%;
        margin-bottom: 300px;

        h5 {
            position: relative;
            z-index: 8;
            margin-bottom:8px;
        }

        h2 {
            transform: all 0.3s ease;
            max-width:1400px;
            width:100%;
            span {
       
                    color: $white;
       
            }
        }
    }

    h5 {
        color: $white;

        span {
            opacity: 0.5;
        }
    }

}
}