@import "../../../scss/variables";

section.about_process {

    background-color: $primary;
padding-bottom:56px;
    .content_wrap {

        .title {
            h2 {
                color: $white;
            }

        }

        .process_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 0px;
            overflow: auto;

            .process_card {
                border-left: 1px solid $white;
                position: relative;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                min-width: 280px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                p {
                    color: $white;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    justify-self: flex-start;
                    gap:0px;

                    &.go_contact {
                        position: relative;
                        cursor: pointer;

                        &::after {
                            content: "";
                            height: 1px;
                            background-color: $white;
                            width: Calc(100% - 60px);
                            position: absolute;
                            bottom: -4px;
                            left: 24px;
                            transition: all 0.3s ease-in-out;
                        }

                        span {
                            margin-left: 8px;
                            display: grid;
                            place-items: center;
                            height: 32px;
                            width: 32px;
                            border-radius: 50%;
                            background-color: $secondary;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.3s ease-in-out;

                            svg {
                                color: $primary;
                                font-size: 20px;
                            }

                            
                        }

                        &:hover {
                     gap:8px;
                     &.go_contact{
                        &::after{
                            width:0px;
                        }
                     }
                        }


                    }

                }

                .curve_box {
                    width: 100%;
                    padding-bottom: 100%;
                    border-radius: 50%;
                    border: 1px dashed $white;
                    transform: translateY(50%);
                    transition: all 0.3s ease-in-out;
                    position: relative;

                    .icon_wrap {
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        background-color: $secondary;
                        position: absolute;
                        top: 0px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: grid;
                        place-items: center;

                        svg {
                            font-size: 24px;
                            color: $primary;
                        }

                    }
                }

                p {
                    transition: all 0.3s ease-in-out;
                }




            }

        }
    }
}