@import "../../../scss/variables";
@import "../../../scss/mixin";

section.details{
    background-color: $secondary;
    position:relative;
    z-index:2;
    .content_wrap{
      h3{
        margin-bottom:16px;        
      }  
      p{
        margin-bottom:16px;
      }
      .bi_grid {
        padding-bottom:60px;
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        gap: 48px;
        @media only screen and (max-width:$laptop){
            grid-template-columns: 0.3fr 1fr;
        }
        @media only screen and (max-width:$tab){
            gap:24px;
            grid-template-columns:1fr;
        } 

        h3 {
            color: $primary;
            &.detail{
                @media only screen and (max-width:$tab){
                    @include font('DMSans-Medium', 24px , 28px, 400, $primary);
                }
                @media only screen and (max-width:$mobile){
                    @include font('DMSans-Medium', 20px , 24px, 400, $primary);
                }
            }
            &.left{
                align-self:flex-start;
                position:sticky;
                top:80px;
                margin-bottom:0px;
                @media only screen and (max-width:$tab){
                    position:relative;
                    top:0px;
                }
            }
        }


        .right {
            h3 {
                color: $grey;
            }

            .inner h3 {
                display: inline;
                line-height: 1.3;
            }

            .text-container {
                position: relative;
                overflow: hidden;
                display: block;
            }

            .highlighted-text:before {
                content: attr(data-text);
                display: inline-block;
                opacity: 0.8;
            }

            .highlighted-text {
                color: $primary;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                z-index: 0;
                transition: clip-path 0.1s ease;
                text-shadow: 0 0 0 $grey;
            }

           
        }

    }

    .tri_info{
        
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap:16px;
        padding-top:56px;   
        @media only screen and (max-width:$laptop){
            grid-template-columns: 1fr 1fr;
        } 
        @media only screen and (max-width:$mobile){
            grid-template-columns: 1fr;                        
        } 
        .project_info{                  
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap:8px;
            p{
                color:$primary;
                &.p_second{
                    color:$grey;
                    margin:0px;

                }
            }
        }

    }
    }
}