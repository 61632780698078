@import "../../scss/variables";
@import "../../scss/mixin";

.feed_card {
    border: 1px solid $secondary;
    border-radius: 16px;
    opacity: 0.5;
    transform: scale(0.8);
    transition: all 0.6s ease-in-out;
    overflow: hidden;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;

    @media only screen and (max-width:1300px) {
        opacity: 1;
        transform: scale(1);
    }

    @media only screen and (max-width:$tab) {
        opacity: 1;
        transform: scale(1);
    }

    @media only screen and (max-width:769px) {
        min-height: auto;
    }


    .video_wrap {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        .overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            z-index: 2;
            background-color: rgba($primary, 0.4);
            display: grid;
            place-items: center;

        }

        .hovered_overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            z-index: 2;
            background-color: rgba($primary, 0.4);
            display: grid;
            place-items: center;
            display: none;
            transition: all 0.3s ease-in-out;

        }

        video {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        &:hover {
            .hovered_overlay {
                display: grid;
            }
        }
    }

    &.active {
        opacity: 1;
        transform: scale(1);

        @media only screen and (max-width:1300px) {
            opacity: 0.6;
            transform: scale(0.9);
        }

        @media only screen and (max-width:769px) {
            opacity: 1;
            transform: scale(1);
        }

    }

    p {
        width: 100%;
        color: $secondary;

        &.feed {
            padding: 24px;
            min-height: 350px;

            @media only screen and (max-width:750px) {
                min-height: 250px;
            }
        }


    }

    .profile {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 24px;

        .img_wrap {
            height: 56px;
            width: 56px;
            border-radius: 50%;
            background-color: $secondary;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .logo_wrap{
            width:80px;
            img{
                max-width:80px;
                object-fit: contain;
            }
        }

        .text {
            p {
                font-size: 20px;
                line-height: 24px;

                &.post {
                    color: $greySecondary;
                    opacity: 0.7;
                }
            }
        }
    }
}