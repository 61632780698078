@import "../../../scss/variables";

section.service_client {
    background-color: $primary;

    .content_wrap {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;
            margin-bottom: 56px;

            h2 {
                color: $white;
            }
        }

        .slick-slider {
            button.slick-arrow {
                display: none !important;
            }

            .slick-list {
                .slick-track {
                    .slick-slide {
                        padding: 0px 8px;

                        @media only screen and (max-width:450px) {
                            padding: 0px 0px;
                        }

                        .client_card_wrap {
                            padding-bottom: 100%;
                            position: relative;
                            background-color: $offDark;
                            border-radius: 8px;
                            transform-origin: left center;
                            overflow: hidden;



                            .logo_wraper {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                width: 100%;
                                height: 100%;
                                display: grid;
                                place-items: center;
                                padding: 24px;
                                transform: scale(1);
                                transition: all 0.3s ease-in-out;

                                &:hover {
                                    transform: scale(1.05);
                                }

                                img {
                                    max-height: 100%;
                                    max-width: 100%;
                                    width: 100%;
                                    object-fit: contain;

                                }
                            }
                        }
                    }
                }
            }
        }








    }
}