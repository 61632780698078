
// Colors
$primary: #020202;
$secondary: #dadada;
$ternery: #f0efef;
$grey:#5f6368;
$mainGrey:#797979;
$greySecondary:#e2e2e2;
$offDark:#0f0f0f;
$lightOffDark:#232323;
$mediumText:#a0a0a0;

$border:#20202066;

$white:#ffffff;






// Break Points

$bigDesk:1500px;
$desktop: 1279px;
$laptop: 992px;
$tab: 769px;
$mobile: 479px;
$menubreak: 1150px;




// Box-shadow

$hoverShadow:rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;