@import "../../../scss/variables";

section.web_dev_landing {
    background-color: $primary;
    position: relative;

    @media only screen and (max-width:$mobile) {
        padding-bottom: 0px;
    }

      padding-top:150px;

  @media only screen and (max-width:$tab) {
    padding-top: 100px;
  }

  @media only screen and (max-width:$mobile) {
    padding-top: 80px;
  }

    .overlay_bg {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0px;
        right: 0px;
        background-color: rgba(0, 0, 0, 0.695);
    }

    img.img_bg {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        top: 0px;
        right: 0px;
        object-fit: cover;


    }

    .content_wrap {
        position: relative;
        z-index: 2;

        .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;
            max-width: 1000px;
            width: 100%;

            h1 {
                color: $white;
            }

            p.para_text {
                color: $white;
            }

            button {
                margin-top: 8px;
            }
        }

    }
}