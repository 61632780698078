@import "../../../scss/variables";
@import "../../../scss/mixin";

section.blog_detail_landing{
    background-color: $secondary;
    .content_wrap{

        .category_line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;

            .category {
                padding: 16px;
                border-radius: 32px;
                background-color: $primary;

                p {
                    color: $white;
                }

            }
          
        }
        h2{
            max-width:1100px;
            width:100%;
            margin-top:24px;
            margin-bottom:48px;
        }
        .img_wrap{
            margin:24px 0px;
            img{
                width:100%;
                border-radius:32px;
            }
        }
        .social_line{
display:flex;
justify-content: space-between;
align-items: center;
        
        .left{
            label{
                @include font('DMSans-Medium', 18px, 20px, 400, $mainGrey);
                margin-bottom:8px;
            }
            p.p_second{
                color:$primary;
            }
        }
        .right{
            display:flex;
            justify-content:flex-end;
            align-items: center;
            gap:16px;
            a{
                svg{
                    font-size:24px;
                    color:$primary;
                    transition:all 0.3s ease-in-out;
                }
            
            }

        }
    }
    }
}