@import "../../../scss/variables";

section.service_landing {
    background-color: $primary;

    .content_wrap {
        h2 {
            color: $white;

            span {
                color: $grey;
            }
        }

        .tab_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 0px;
            column-gap: 48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 0px;
            }

            a {
                border-top: 1px solid rgba($grey, 0.5);
                padding: 16px;
                background-color: $primary;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: all 0.3s ease-in-out;

                p {
                    transition: all 0.3s ease-in-out;

                    span {
                        color: $white;
                        margin-left: 8px;
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            left: 0px;
                            bottom: -2px;
                            height: 1px;
                            border-bottom: 1px solid $white;
                            width: 0%;
                            transition: all 0.3s ease-in-out;



                        }
                    }
                }

                svg {
                    color: $white;
                    font-size: 24px;
                }

                &:hover {
                    background-color: rgba($lightOffDark, 0.4);
                    padding: 16px 8px 16px 16px;

                    p {
                        color: $white;

                        span {
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }

            }

        }
    }
}