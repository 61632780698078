@import "../../scss/variables";
@import "../../scss/mixin";


footer {
    background-color: $secondary;
    position:relative;
    z-index:4;
    section.footer {
        .content_wrap {
            border-top: 1px solid $primary;
            padding-top:120px;
            @media only screen and (max-width:$laptop){
                padding-top:80px;
            }
            @media only screen and (max-width:$tab){
                padding-top:60px;
            }

            .footer_grid {
                display: grid;
                grid-template-columns: 1.5fr 1fr 1fr 1fr 1.3fr;
                gap: 48px;

                @media only screen and (max-width:$desktop) {
                    grid-template-columns: 1fr 1fr 1fr;
                }

                @media only screen and (max-width:$mobile){
                    grid-template-columns: 1fr 1fr;
                column-gap:24px;
                row-gap: 32px;;

                }

                .logo_wrap {
                    @media only screen and (max-width:$desktop) {
                        grid-column: 1 / span 3;
                    }
                    @media only screen and (max-width:$mobile){
                        grid-column: 1 / span 2;
                    }

                    img {
                        max-width: 200px;
                        width: 100%;
                        object-fit: contain;
                    }

                    .news_letter {
                        margin-top: 56px;
                        @media only screen and (max-width:$tab){
                            margin-top:32px;
                        }
                        h4{
                            color:$primary;
                            margin-bottom:8px;
                        }
                        p.p_second{
                            @include font('DMSans-Medium', 18px, 20px, 500, $grey);
                        }

                        .success_msg{
                       margin-top:8px;
                       
                        }
                    }

                    form {
                        margin-top: 16px;

                        input {
                            border-style: none;
                            background-color: transparent;
                            border-bottom: 1px solid $grey;
                            width:calc(100% - 100px);
                            padding: 8px 0px;
                            @include font('DMSans-Medium', 18px, 20px, 500, $grey);
                        }

                        button {
                            padding: 10px 20px;
                            margin-top: 16px;
                            border-style: none;
                            background-color: $primary;
                            border-radius: 24px;
                            @include font('DMSans-Medium', 1.25rem, 24px, 500, $white);
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                background-color: $lightOffDark;
                            }
                        }
                    }

                }

                .link_col {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 16px;

                    a {
                        transition: all 0.3s ease-in-out;
                        color: $grey;

                        &:hover,
                        &.active {
                            color: $primary;
                        }
                    }

                    .social_icons {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 16px;

                        a {


                            svg {
                                font-size: 24px;
                            }
                        }
                    }

                    button.go_top {
                        height: 80px;
                        width: 80px;
                        border-radius: 50%;
                        border: 1px solid $grey;
                        position: relative;
                        margin-top: 24px;
                        background-color: transparent;
                        overflow: hidden;
                        transition: all 0.6s ease-in-out;

                        svg {
                            font-size: 32px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: $primary;
                            z-index: 2;
                            transition: all 0.3s ease-in-out;
                        }

                        .slides {
                            position: absolute;
                            top: 110%;
                            left: 50%;
                            transform: translateX(-50%);
                            background-color: $primary;
                            height: 100%;
                            width: 100%;
                            border-radius: 50%;
                            z-index: 0;
                            transition: all 0.3s ease-in-out;

                        }

                        &:hover {
                            transform: scale(1.05);

                            .slides {
                                top: 0%;
                            }

                            svg {
                                transform: translate(-50%, -80%);
                                color: $white;
                            }
                        }
                    }

                }

                .social_col {
                    @media only screen and (max-width:$desktop) {
                        grid-column: 1 / span 3;
                    }
                    @media only screen and (max-width:$mobile){
                        grid-column: 1 / span 2;
                    }
                }
            }

            .copy_right {
                margin-top: 80px;
                padding: 24px 0px;
                border-top: 1px solid $grey;
                @media only screen and (max-width:$mobile){
                    text-align: center;
                }

                p{
                    color:$primary;
                }
            }

        }
    }
}