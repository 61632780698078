@import "../../../scss/variables";

section.faq {
    background-color: $secondary;

    .content_wrap {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;

            h2 {
                span {
                    color: $grey;
                }
            }
        }

        .faq_acc_wrap {
            margin-top: 56px;

            .accordion {
                border-style: none;
                .accordion-item {
                    border-style: none;
                    border-bottom: 1px solid rgba($grey, 0.6);
                    overflow: hidden;
                    margin-top:24px;
              
                    .accordion-header {
                        border-style: none;
                        button {
                            background-color: $secondary;
                            padding-left:46px;
                            border-style: none;

                            span {
                                &.v {
                                    width: 2px;
                                    height: 20px;
                                    background-color: $primary;
                                    position: absolute;
                                    left:19px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    transition:all 0.6s ease-in-out;
                                }

                                &.h {
                                    width: 20px;
                                    height: 2px;
                                    background-color: $primary;
                                    position: absolute;
                                    left:10px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                   

                            p {
                                color: $primary;
                            }

                            &::after {
                                display: none;
                            }

                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }

                        }
                        button[aria-expanded="true"]{
                            span{
                                &.v{
                                    transform:translateY(-50%) rotate(270deg);
                                }
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {}
                    }

                    &:has(button[aria-expanded="true"]) {
                        background-color: $white;
                        border-radius: 16px;

                        button {
                            background-color: $white;
                        }
                    }

                    &:hover {
                        background-color: $white;
                        border-radius: 16px;
                        border: 1px solid rgba($grey, 0.6);

                        .accordion-header {
                            button {
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}