@import "../../../scss/variables";
@import "../../../scss/mixin";

.services {
    padding-top: 48px;
    background-color: $primary;
}

section.service_mid_tab {
    position: sticky;
    top: 70px;
    z-index: 99;
    height: 0px;


    .content_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;

        .md_tab_line {
            overflow: auto;

            @media only screen and (max-width:$mobile) {
                display: none;
            }

            .service_tab_wraper {
                position: relative;
                min-width: 1100px;
                border-radius: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;
                border: 1px solid $grey;
                padding: 8px 16px;
                background-color: rgba($primary, 0.8);
                backdrop-filter: blur(4px);
                background-color: rgba($primary, 0.4);

                @media only screen and (max-width:$tab) {
                    min-width: 1060px;
                    gap: 16px;
                }

                a {
                    padding: 8px 16px;
                    @include font('DMSans-Regular', 1.25rem, 1.2, 400, rgba($white, 0.5));
                    position: relative;
                    pointer-events: none;
                    cursor: pointer;

                    &::after {
                        height: 1px;
                        width: 90%;
                        content: "";
                        background-color: $white;
                        position: absolute;
                        width: 0%;
                        bottom: -8px;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: all 0.3s ease-in;
                    }

                    &.active {
                        background-color: transparent;
                        color: $white !important;

                        &::after {
                            width: 90%;
                        }
                    }
                }

                .btn_wrap {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    gap: 24px;
                    padding: 8px 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media only screen and (max-width:$tab) {
                        gap: 16px;
                    }

                    button {
                        padding: 8px 16px;
                        @include font('DMSans-Regular', 1.25rem, 1.2, 400, rgba($white, 0.5));

                    }
                }


            }
        }



    }
}