@import "../../../scss/variables";


section.case_study_list {
    background-color: $secondary;
    ;

    .content_wrap {
        h2 {
            max-width: 1000px;
            width: 100%;

            span {
                color: $grey;
            }
        }

        p.title_para {
            margin-top: 16px;
        }

        .pills_wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            margin-top:48px;

            button {
                padding: 12px 16px;
                background-color: $ternery;

                border-style: none;
                border-radius: 32px;
                transition: all 0.3s ease-in-out;

                p {
                    color: $primary;
                    transition: all 0.3s ease-in-out;
                }

                &:hover,
                &.active {
                    background-color: $primary;

                    p {
                        color: $white;
                    }
                }
            }
        }

        .case_studies_cards {
            margin-top: 80px;

            @media only screen and (max-width:$tab) {
                margin-top: 48px;
            }


            .case_card {
                cursor: pointer;
                padding: 32px 0px;
                border-bottom: 1px solid $border;
                display: grid;
                grid-template-columns: 1.5fr 1fr;
                gap: 48px;

                @media only screen and (max-width:$laptop) {
                    grid-template-columns: 1fr 1fr;
                }

                @media only screen and (max-width:$tab) {
                    grid-template-columns: 1fr;
                    gap: 32px;
                }

                .text {
                    .tag_wrap {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        p {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }

                        span {
                            height: 8px;
                            width: 8px;
                            border-radius: 50%;
                            background-color: $grey;
                            display: inline-block;
                            margin: 0px 8px;

                            &.none {
                                display: none;
                            }
                        }
                    }

                    h3 {
                        margin-top: 16px;
                        max-width: 500px;
                        width: 100%;
                        margin-bottom: 48px;
                        color: $primary;
                    }

                    p {
                        &.dark {
                            color: $primary;
                            margin-bottom: 8px;
                        }
                    }
                }

                .img_wrap {
                    @media only screen and (max-width:$tab) {
                        order: -1;
                    }

                    img {
                        width: 100%;
                        border-radius: 16px;
                    }
                }

            }
        }

    }
}