@import "../../scss/variables";
@import "../../scss/mixin";

header {
    position: sticky;
    top: 0px;
    background-color: $ternery;
    z-index: 999;
    min-width: 100%;

    @media only screen and (max-width:$menubreak) {
        padding: 16px 0px;
    }

    section.main_header {
        .content_wrap {
            position: relative;



            &.grids {
                display: grid;
                grid-template-columns: 0.3fr 1fr 0.5fr;

                @media only screen and (max-width:$menubreak) {
                    grid-template-columns: 1fr;
                }
            }

            .logo_wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                user-select: none;
                -webkit-user-drag: none;

                img {
                    max-width: 200px;
                    width: 100%;
                    user-select: none;
                    -webkit-user-drag: none;
                }

                button {
                    display: none;
                    padding: 0px;
                    border-style: none;
                    background-color: transparent;

                    svg {
                        font-size: 24px;
                        color: $primary;
                    }
                }

                @media only screen and (max-width:$menubreak) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    button {
                        display: block;
                    }
                }
            }

            nav {

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;

                @media only screen and (max-width:$menubreak) {
                    display: none;
                }

                a {
                    @include font('DMSans-Medium', 1.2rem, 1.2, 500, rgba($grey, 0.9));

                    &:hover,
                    &.active {
                        color: $primary;
                    }
                }

                .tab_dd {
                    padding: 20px 0px;

                    p {
                        cursor: pointer;
                        @include font('DMSans-Medium', 20px, 24px, 500, $mainGrey);

                        span {
                            svg {
                                font-size: 24px;
                                transform: rotate(0deg);
                                transition: all 0.2s ease-in;
                            }
                        }

                        &.active{
                            color:$primary;
                            span{
                                svg{
                                    color:$primary;
                                }
                            }
                        }

                    }

                    .dd_panel {
                        position: absolute;
                        top: 60px;
                        left: 0px;
                        width: 100%;
                        transform: scaleY(0);
                        transition: all 0.2s ease-in-out;
                        transform-origin: top center;
                        padding-top: 8px;
                        overflow: auto
                    }

                    &:hover {
                        p {
                            span {
                                svg {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .dd_panel {
                            transform: scaleY(1);
                        }
                    }
                }
            }

            .contact_side {

                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;

                @media only screen and (max-width:$menubreak) {
                    display: none;
                }

            }
        }


    }
}