@import "../../../scss/variables";




section.home_landing {
    position:sticky;
    top: 72px;
    height:80vh;   
    @media only screen and (max-width:$mobile){
        height:auto;   
    } 
    .content_wrap {
        position: relative;
        z-index:0;
        @media only screen and (max-width:$mobile){
            padding-top:48px;
        }
        .content {
            width: 75%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 24px;

            @media only screen and (max-width:$laptop) {
                width: 100%;
                gap: 16px;
            }
        }
    }

}