@import "../../scss/variables";

button.hover_arrow_btn {
    height: 56px;
    width: 56px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid $white;
    display: grid;
    place-items: center;
    position: relative;
    overflow: auto;

    

    svg {
        color: $white;
        font-size: 18px;
    }

    span {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: -110%;
        display: grid;
        place-items: center;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
        background-color: $secondary;
        svg {
            color: $primary;
        }
    }

    &:hover {
        span {
            left: 0%;
        }
    }

    @media only screen and (max-width:$mobile){
        height:48px;
        width:48px;
        svg{
            font-size:16px;
        }
    }


}