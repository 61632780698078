@import "../../../scss/variables";
@import "../../../scss/mixin";

.solutions_dd_sec {
    background-color: $ternery;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 24px;
    border-radius: 16px;

    .solutions_link_grid {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h5 {
            margin-bottom: 8px;
        }

        .link_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
        }

        a {
            padding: 16px 20px 16px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1px solid $secondary;
            transition: all 0.3s ease-in-out;

            p {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                transition: all 0.3s ease-in-out;

            }

            &:hover,
            &.active {
                padding: 16px 16px 16px 16px;
                border: 1px solid $primary;
                background-color: $secondary;

                p {
                    color: $primary !important;
                }
            }
        }
    }

}