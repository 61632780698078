@import "../../../scss/variables";


section.tools {
    background-color: $primary;
    position: relative;
    .content_wrap {
        position: relative;
        z-index: 2;

        h2 {
            color: $white;
            padding-bottom: 56px;
            text-align: center;
        }

        .tools_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 48px;

            @media only screen and (max-width:1400px) {
                gap: 32px;
            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr 1fr;
                gap: 24px;
            }

            @media only screen and (max-width:$mobile) {
                gap: 16px;
            }

            .tool_box {
                width: 100%;
                padding-bottom: 100%;
                position: relative;

                .tool_circle {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(1);
                    background-color: rgba($white, 0.05);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                    border-right: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    transition: all 0.6s ease-in-out;
                    padding:8px;
                    @media only screen and (max-width:$tab) {
                        gap: 8px;
                    }

                    .icon_wrap {
                        padding: 16px;
                        border-radius: 8px;
                        background-color: $ternery;
                        display: grid;
                        place-items: center;

                        svg {
                            color: $primary;
                            font-size: 32px;
                        }

                        @media only screen and (max-width:$tab) {
                            padding: 8px;

                            svg {
                                font-size: 24px;
                            }
                        }
                    }

                    h5 {
                        color: $white;
                        white-space: no-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @media only screen and (max-width:$mobile){
                            font-size:16px;
                            line-height: 20px;
                        }
                        @media only screen and (max-width:400px){
                            font-size:14px;
                            line-height: 18px;
                        }
                    }

                    &:hover {
                        background-color: rgba($white, 0.08);
                        transform: translate(-50%, -50%) scale(0.95);
                        border-right: 1px solid rgba($white, 0.3);
                        border-bottom: 1px solid rgba($white, 0.3);
                    }
                }

            }
        }
    }
}