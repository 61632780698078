@import "../../../scss/variables";

.about_landing_wrap {
    background-color: $primary;
    min-height: 100vh;
    overflow: hidden;


    section.about_landing {

        .content_wrap {
            h1 {
                color: $white;

                span {
                    color: $grey;
                }
            }

            button {
                margin-top: 24px;
            }
        }
    }

    section.logo_slide {
        background-color: $primary;
        bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        z-index: 0;

        .img_wraper {
            padding: 0px 100px;

            img {
                height: 80px;
            }
        }
    }

    section.about_second {
        background-color: $secondary;
        position: relative;
        z-index: 4;

        .content_wrap {

            .bi_sec {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 48px;

                @media only screen and (max-width:900px) {
                    grid-template-columns: 1fr;
                    gap: 24px;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    p {
                        margin-top: 16px;
                    }
                }



                .img_sec {
                    img {
                        width: 100%;
                        object-fit: contain;
                        border-radius: 16px;
                    }
                }
            }

        }
    }

    .animated_circle {
        z-index: 1;
        position: fixed;
        bottom: 80px;
        // right: 80px;
        border-radius: 1000px;
        background-color: $secondary;
        display: grid;
        place-items: center;

        img {
            max-width: 48px;
        }

        @media only screen and (max-width:$tab) {
            right: 48px;
            bottom: 48px;
        }

        @media only screen and (max-width:$mobile) {
            right: 16px;
            bottom: 16px;
        }

        &.desktop {
            @media only screen and (max-width:$mobile) {
                display: none;
            }
        }

        &.mobile {
            display: none;

            @media only screen and (max-width:$mobile) {
                display: grid;
            }
        }
    }
}