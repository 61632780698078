@import "../../scss/variables";
@import "../../scss/mixin";


button.primary_btn {
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-style: none;
    background-color: transparent;
    gap: 0px;
    transition: all 0.3s ease-in-out;

    &:disabled {
        opacity: 0.5;
    }

    p {
        position: relative;
        padding: 12px 32px;
        background-color: $primary;
        @include font('DMSans-Regular', 1.25rem, 22px, 400, $white);
        border-radius: 32px;

        @media only screen and (max-width:$tab) {
            @include font('DMSans-Regular', 1.20rem, 20px, 400, $white);
        }

        @media only screen and (max-width:$mobile) {
            @include font('DMSans-Regular', 1.125rem, 20px, 400, $white);
        }

    }

    .circle {
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background-color: $ternery;
        display: grid;
        place-items: center;
        position: relative;
        overflow: hidden;       
        transition: all 0.3s linear;
        border:1px solid $primary;
        @media only screen and (max-width:$tab) {
            height: 44px;
            width: 44px;
        }

        svg {
            color: $primary;
        }

        .slider {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: $primary;
            display: grid;
            place-items: center;
            position: absolute;
            top: 50%;
            left: -100%;
            transform: translateY(-50%);
            transition: all 0.3s ease-in-out;

            svg {
                color: $white;
                font-size:20px;
            }

        }
    }


    &.dark {

        p {
            background-color: $ternery;
            color: $primary;

            &:after {
                background-color:$ternery;
            }
        }

        .circle {
            background-color:$primary;
            border:1px solid $ternery;

            svg {
                color:$ternery;
                font-size: 24px;
            }

            .slider {
                background-color: $ternery;
                height:100%;
                width:100%;
                border-radius: 50%;
                svg {
                    color: $primary;
                }
            }

        }

    }

    &:hover {

        gap: 8px;
        

        .circle {
            transform: translateX(4px) rotate(0deg);

            .slider {
                left: 0px;
            }
        }
    }

}