@import "../../../scss/variables";

section.feed_back_slide {
    background-color: $primary;
    position: relative;
    z-index: 3;

    .content_wrap {
        .title {
            h2 {
                color: $white;
                text-align: center;
            }
        }
    }
}

.padding_cover {
    background-color: $primary;
    position: relative;
    z-index: 3;
}

#feed_slider_wrap {
    background-color: $primary;
    position: relative;
    z-index: 3;

    @media only screen and (max-width:$laptop) {
        padding-right: 16px;
        padding-left: 16px;
    }

    position: relative;

    .slick-slider {
        button.slick-arrow {
            display: none !important;
        }

        .slick-list {

            .slick-track {
                display: flex;
                align-items: center;
                gap: 0px;

                @media only screen and (max-width:$tab) {
                    align-items: center;
                }

                .slick-slide {
                    height: 100%;

                    @media only screen and (max-width:1300px) {
                        padding: 0px 8px;
                    }


                    .slides_wrap {
                        height: 100%;
                        align-self: stretch;
                    }
                }
            }
        }
    }



    button {
        height: 84px;
        width: 84px;
        border-radius: 50%;
        border: 1px solid rgba($grey, 0.5);
        display: grid;
        place-items: center;
        background-color: $offDark;
        transition: all 0.3s ease-in-out;

        @media only screen and (max-width:$laptop) {
            height: 60px;
            width: 60px;
        }

        svg {
            font-size: 24px;
            color: $secondary;
        }

        &:hover {
            background-color: $secondary;

            svg {
                color: $primary;
            }
        }

        &.prev_btn {
            position: absolute;
            top: 50%;
            left: 48px;
            transform: translateY(-50%);
            overflow: hidden;

            @media only screen and (max-width:$laptop) {
                top: -16px;
                transform: translateY(-100%);
                left: 16px;

            }

            span {

                &.hovered {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    transform: translate(50%, -50%);
                    transition: all 0.3s ease-in-out;
                    display: none;
                }



                &.fixed {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(50%, -50%);

                }
            }

            &:hover {
                span {
                    &.hovered {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: all 0.3s ease-in-out;
                        display: block;
                    }



                    &.fixed {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: none;

                    }
                }
            }

        }

        &.next_btn {
            position: absolute;
            top: 50%;
            right: 48px;
            transform: translateY(-50%);
            overflow: hidden;

            @media only screen and (max-width:$laptop) {
                top: -16px;
                transform: translateY(-100%);
                right: 16px;
            }

            span {

                &.hovered {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.3s ease-in-out;
                    display: none;
                }



                &.fixed {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                }
            }

            &:hover {
                span {
                    &.hovered {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: all 0.3s ease-in-out;
                        display: block;
                    }



                    &.fixed {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: none;

                    }
                }
            }
        }
    }
}

section.progress_sec {
    background-color: $primary;
    position: relative;
    z-index: 3;
    .content_wrap {

        .progress_bar {
            width: 100%;
            height: 2px;
            background-color: rgba($secondary, 0.2);
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .filler {
                background-color: $secondary;
                height: 100%;
                transition: all 0.4s ease-in-out;
            }

        }
        .btn_sec{
            margin-top:48px;
            display:flex;
            justify-content: center;
            align-items: center;
        }

    }
}


section.about_cta {
    background-color: $secondary;
    position: relative;
    z-index: 3;
    .content_wrap {
        button {
            margin-top: 24px;
        }
    }
}