@import "../../../scss/variables";

section.home_cta{
    background-color:$secondary;
    position:relative;
    z-index:4;

    

    .content_wrap{
        background-color: $primary;
        border-radius: 24px;
        position:relative;
        overflow:hidden;
        background:url("../../../assets/images/services/serviceBg.webp");
        background-repeat: no-repeat;
        background-size: cover;
        transform-origin: left center;
        .text{
            position:relative;
            z-index:2;
        }
        h2{
            color:$white;
            margin-bottom:24px; 
        }
      
    }

}