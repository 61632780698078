@import "../../scss/variables";
@import "../../scss/mixin";

.offcanvas {
    &.menu_sider {
        background-color: $secondary;
        height: 100vh;

        .offcanvas-header {
            .offcanvas-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding-bottom: 12px;
                border-bottom: 1px solid rgba($grey, 0.5);

                img {
                    max-width: 150px;
                    width: 100%;
                    object-fit: contain;
                }

                button {
                    padding: 0px;
                    border-style: none;
                    background-color: transparent;

                    svg {
                        color: $primary;
                        font-sizE: 24px;
                    }
                }
            }
        }

        .offcanvas-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;

            button {
                border-style: none;
                background-color: transparent;
                @include font('DMSans-Medium', 18px, 20px, 500, $primary);
            }

            a {
                position: relative;
                background-color: transparent;
                color: $primary;
                border-radius: 24px;
                padding: 8px 16px;
                color: $grey;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 4px;
                    left: 16px;
                    width: 0%;
                    height: 1px;
                    background-color: $primary;
                    transition: all 0.3s ease-in-out;
                }

                &:hover,
                &.active {

                    color: $primary;

                    &::after {
                        width: calc(100% - 16px);
                    }
                }
            }

            button.contact {
                border-radius: 24px;
                padding: 8px 16px;
                color: $white;
                background-color: $primary;
                margin-left: 16px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: $offDark;
                }
            }

            .social_connect {
                width: 100%;
                margin-top: 24px;
                padding-top: 24px;
                border-top: 1px solid $grey;
                max-width: 350px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                gap: 16px;

                @media only screen and (max-width:$mobile) {
                    gap: 8px;
                }

                a {
                    @media only screen and (max-width:$mobile) {
                     padding:8px
                    }
                    &::after {
                        display: none;
                    }

                    svg {
                        font-size: 22px;
                    }
                }
            }



            .accordion {
                width: 100%;
                border-style: none;
                background-color: transparent;

                .accordion-item {
                    border-style: none;
                    background-color: transparent;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 8px;

                    .accordion-header {
                        background-color: transparent;

                        button {
                            background-color: transparent;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 8px;
                            color: $grey;
                            padding: 8px 16px;

                            svg {
                                transition: all 0.2s ease-in-out;
                                transform: rotate(0deg)
                            }

                            &:after {
                                display: none;
                            }

                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }

                            &[aria-expanded=true] {
                                svg {
                                    transform: rotate(180deg)
                                }
                            }
                        }

                        &.dark {
                            button {
                                color: $primary;

                                svg {
                                    color: $primary;
                                }
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            // background-color: $white;
                            max-width: 280px;
                            width: 100%;

                            padding: 8px;
                            border-radius: 8px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;

                            a {
                                padding: 8px 16px;
                                border-radius: 24px;
                                border: 1px solid $grey;
                                margin-bottom: 8px;
                                transition: all 0.3s ease-in-out;

                                &:after {
                                    display: none;
                                }

                                &.active,
                                &:hover {
                                    border: 1px solid $primary;
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}