@import "../../../scss/variables";

section.services_sec {
    background-color: $primary;
    overflow: hidden;

    .content_wrap {
        h2 {
            color: $white;

            @media only screen and (max-width:$tab) {
                margin-top: 56px;
            }
        }

        .bi_grid {
            overflow: hidden;
            margin-top: 56px;
            border: 1px solid grey;
            border-radius: 16px;
            display: grid;
            grid-template-columns: 0.6fr 1fr;
            gap: 48px;
            background-color: rgba($white, 0.02);
            backdrop-filter: blur(5px);
            transform: scale(1);
            transition: all 0.3s ease-in-out;

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr;
                gap: 32px;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                padding: 0px;
                gap:24px;
            }
            @media only screen and (max-width:$mobile) {
                gap:16px;
            }

            .left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;

                @media only screen and (max-width:$tab) {
                    padding: 24px 24px 0px 24px;
                }

                @media only screen and (max-width:$tab) {
                    padding: 16px 16px 0px 16px;
                }

                h3 {

                    color: $white;

                    span {
                        color: $grey;
                    }
                }

                button {
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                }
            }

            .right {

                img {
                    width: 100%;
                    max-height: 400px;
                    object-fit: cover;
                }
            }

            &:hover {
                background-color: rgba($white, 0.04);
                backdrop-filter: blur(9px);
                transform: scale(1.01);

                .left {
                    button {
                        opacity: 1;
                    }
                }

            }

        }

        .bi_card {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;
            margin-top: 48px;

            @media only screen and (max-width:$laptop) {
                gap: 32px;
                margin-top: 32px;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 24px;
                margin-top: 24px;
            }

            @media only screen and (max-width:$mobile) {
                gap: 16px;
                margin-top: 16px;
            }


            .info_card_wrap {
                border: 1px solid grey;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 48px;
                background-color: rgba($white, 0);
                transform: scale(1);
                transition: all 0.3s ease-in-out;
                height: 100%;

                @media only screen and (max-width:$tab) {
                    padding: 24px;
                }

                h3 {
                    color: $white;
                }

                svg {
                    color: $white;
                    font-size: 48px;
                }

                &:hover {
                    transform: scale(1.02);
                    background-color: rgba($white, 0.05);
                }
            }

        }


        .bi_blog {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;
            margin-top: 48px;

            @media only screen and (max-width:$laptop) {
                gap: 32px;
                margin-top: 32px;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 24px;
                margin-top: 24px;
            }

            @media only screen and (max-width:$mobile) {

                gap: 16px;
                margin-top: 16px;
            }

            .blog_card {
                border: 1px solid grey;
                border-radius: 16px;
                transition: all 0.3s ease-in-out;
                transform: scale(1);
                height: 100%;
                background-color: rgba($white, 0.02);

                @media only screen and (max-width:$tab) {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                img {
                    width: 100%;
                    border-radius: 16px;

                    &.h {
                        max-height: 400px;
                        object-fit: cover;
                    }
                }

                .text {
                    padding: 48px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 16px;
                    transition: all 0.3s ease-in-out;

                    @media only screen and (max-width:$laptop) {
                        padding: 32px;
                    }

                    @media only screen and (max-width:$tab) {

                        padding: 24px;
                    }

                    @media only screen and (max-width:$mobile) {
                        padding: 16px;
                    }

                    h3 {
                        color: $white;

                        span {
                            color: $grey;
                        }
                    }

                    button {
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }

                }

                &:hover {
                    transform: scale(1.02);
                    background-color: rgba($white, 0.04);

                    .text {


                        button {
                            opacity: 1;

                        }
                    }
                }
            }
        }
    }
}