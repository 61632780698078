@import "../../../scss/variables";
@import "../../../scss/mixin";

section.img_info {
    background-color: $primary;
    position: relative;
    overflow: hidden;
    z-index:2;

    .content_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        img {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 100%;
            height:100%;
            object-fit:cover;
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            width:60%;
            @media only screen and (max-width:$tab){
                width:100%;
            }

            button{
                margin-top:24px;
            }
            h3{
                @media only screen and (max-width:$tab){
                    @include font('DMSans-Medium', 24px , 28px, 400, $grey);
                }
                @media only screen and (max-width:$mobile){
                    @include font('DMSans-Medium', 20px , 24px, 400, $grey);
                }
                span{
                    color:$white;
                }
            }
          
        }
    }
}


  