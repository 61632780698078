@import "../../../scss/variables";
@import "../../../scss/mixin";

.offcanvas {
    &.contact_sidebar {
        width: 700px;
        padding: 16px 0px;
        background-color: transparent;

        .offcanvas-header {
            background-color: $white;
            padding: 0px;

            .offcanvas-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                width: 100%;
                padding: 40px 24px 0px 40px;

                @media only screen and (max-width:$mobile) {
                    padding: 24px 16px 0px 24px;
                }

                h3 {
                    color: $primary;
                }

                button {
                    border-style: none;
                    background-color: transparent;
                    border-style: none;

                    svg {
                        color: $primary;
                        font-size: 24px;
                    }
                }
            }
        }

        .offcanvas-body {
            background-color: $white;
            padding: 40px;

            @media only screen and (max-width:$mobile) {
                padding: 24px;
            }

            button.primary_btn {
                &:disabled {
                    opacity: 0.5;
                }
            }

            .bi_sec {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;

                &.mt {
                    margin-top: 24px;
                }

                @media only screen and (max-width:$mobile) {
                    grid-template-columns: 1fr;
                    gap: 16px;

                    &.mt {
                        margin-top: 16px;
                    }
                }
            }

            .field_wrap {
                width: 100%;

                input,
                textarea {

                    width: 100%;
                    padding: 16px;
                    @include font('DMSans-Regular', 16px, 20px, 400, $grey);
                    border-radius: 4px;
                    border: 1px solid rgba($grey, 0.4);
                    background-color: transparent;
                    transition: all 0.3s ease-in;

                    @media only screen and (max-width:$mobile) {
                        padding: 12px 16px;
                    }

                    &:focus {
                        outline: none;
                        border: 1px solid $grey;
                    }

                }

                textarea {
                    margin-top: 24px;
                    height: 100px;

                    @media only screen and (max-width:$mobile) {
                        margin-top: 16px;
                    }
                }

                .react-tel-input {
                    height: 54px;

                    @media only screen and (max-width:$mobile) {
                        height: 46px;
                    }

                    input {
                        height: 100%;
                        padding-left: 44px;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }


            }

            .pills_sec {
                margin: 32px 0px;

                @media only screen and (max-width:$mobile) {
                    margin: 16px 0px;
                }

                p {
                    color: $primary;
                }

                .pills_wrap {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 8px;
                    margin: 16px 0px 24px;

                    .pills_block {
                        padding: 8px 16px;
                        border-radius: 16px;
                        border: 1px solid rgba($grey, 0.4);
                        transition: all 0.3s ease-in-out;

                        p {
                            font-size: 14px;
                            line-height: 16px;
                            color: $grey;
                        }

                        &.active {
                            border: 1px solid $primary;

                            p {
                                color: $primary;
                            }
                        }
                    }
                }
            }

            .bottom_text {
                margin-top: 32px;

                @media only screen and (max-width:$mobile) {
                    margin-top: 16px;
                }

                p {
                    a {
                        font-size: inherit;
                        font-family: inherit;

                    }

                    span {
                        color: $primary;
                    }

                    &.call{
                        display:flex;
                        justify-content: flex-start;
                        align-items:center;
                        gap:8px;
                        svg{
                            font-size:22px;
                        }
                    }
                    &.call{
                        display:flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:24px;
                        flex-wrap: wrap;
                        @media only screen and (max-width:$mobile){
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap:8px;
                        }
                    }

                }
            }

            .select_tag {
                height: 54px;
                border: 1px solid rgba($grey, 0.4);
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                padding: 16px;
                position: relative;
                border-radius: 4px;
                @media only screen and (max-width:$mobile) {
                    height: 46px;
                }

                p {
                    @include font('DMSans-Regular', 16px, 20px, 400, $grey);
                }

                span {
                    transition: all 0.3s ease-in-out;

                    svg {
                        color: $grey;
                    }
                }

                .option_wrap {
                    z-index: 3;
                    padding-top: 16px 0px;
                    position: absolute;
                    top: 55px;
                    left: 0px;
                    width: 100%;
                    max-height: 300px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 0px;
                    border: 1px solid rgba($grey, 0.4);
                    transform-origin: top center;
                    transition: all 0.3s ease-in;
                    @media only screen and (max-width:$mobile){
                        top:48px;
                    }
                    .search {
                        position: sticky;
                        top: 0px;
                        left: 0px;
                        background-color: $white;
                        width: 100%;
                        position: relative;

                        input {
                            height: 40px;
                            min-width: 100%;
                        }

                        svg {
                            position: absolute;
                            top: 50%;
                            right: 16px;
                            transform: translateY(-50%);
                            font-size: 18px;
                            color: $grey;
                        }
                    }

                    button {
                        width: 100%;
                        padding: 8px 16px;
                        text-align: left;
                        border-style: none;
                        background-color: $white;
                        @include font('DMSans-Regular', 16px, 20px, 400, $grey);
                        border-bottom: 1px solid rgba($grey, 0.4);
                        transition: all 0.3s ease-in-out;

                        &:hover,
                        &.active {
                            background-color: $secondary;
                            color: $primary;
                        }
                    }
                }
            }


            .success_screen {
                border: 1px solid rgba($grey, 0.4);
                padding: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 8px;
                border-radius: 4px;
                position: relative;

                button.msg_close {
                    border-style: none;
                    background-color: transparent;
                    position: absolute;
                    top: 8px;
                    right: 8px;

                    svg {
                        font-size: 20px;
                        color: $primary;
                    }

                }

                p {
                    text-align: center;
                    @include font('DMSans-Regular', 20px, 24px, 400, $grey);
                }

                h3 {
                    text-align: center;
                    color: $primary;
                }
            }
            .captcha_wrap{
                margin-bottom:24px;
            }
        }
    }

}