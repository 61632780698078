@import "../../../scss/variables";

section.multi_grid_info{
    background-color: $primary;
    .content_wrap{

            h2{
                color:$white;
                &.title{
                    max-width:900px;
                    width:100%;
                }
            }
            h3{
                color:$white;
                margin-top:56px;
            }

            .tri_grid_wrap{
                display:grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap:24px;
                margin-top:56px;

                @media only screen and (max-width:$desktop){
                    grid-template-columns: 1fr 1fr;
                }
                @media only screen and (max-width:$tab){
                    grid-template-columns: 1fr;
                }
                

                .tri_card{
                    display:flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap:60px;
                    background-color: rgba($white, 0.05);
                    padding:32px;
                    border-radius: 16px;
                    border:1px solid rgba($white, 0.2);
                    transform:scale(1);
                    transition:all 0.3s ease-in-out;
                    height:100%;
                    @media only screen and (max-width:$laptop){
                        padding:24px;
                    }
                    @media only screen and (max-width:$tab){
                        padding:16px;
                    }

                    &:nth-child(1){
                        grid-column: 1 / 3;
                        @media only screen and (max-width:$tab){
                            grid-column:unset;
                        }
                    }
                 
                    
                    svg{
                        font-size:48px;
                        color:$white;
                        opacity:0;
                        transition:all 0.3s ease-in-out;
                    }
                    h4{
                        color:$white;
                    }
                    p{
                        margin-top:16px;
                    }

                    &:hover{
                        background-color: rgba($white, 0.08);
                        transform:scale(1.02);
                        svg{
                            opacity:1;
                        }
                    }

                
                }
            }
    }
}