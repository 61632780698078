@import "../../../scss/variables";

section.map_image_info {
    background-color: $secondary;
    position:relative;
    z-index:4;
    .content_wrap {

        p.sub_title {
            color: $primary;
        }

        .map_wraper {
            display: grid;
            place-items: center;
            position: relative;
            margin-top: 56px;

            img {
                width: 100%;
                object-fit: contain;
            }

            button {
                position: absolute;
                border-style: none;
                background-color: transparent;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: #4452f3;

                .flag_wrap {
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    top: 0px;
                    left: 0px;
                    transform: translate(-100%, -100%);
                    padding: 4px;
                    background-color: $white;
                    border-radius: 50% 50% 0px 50%;
                    border: 1px solid rgba($primary, 0.25);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }


                &.a {
                    top:53%;
                    left:70.5%;

                    @media only screen and (max-width:$tab) {
                        .flag_wrap {
                            transform: translate(4px, -100%);
                            border-radius: 50% 50% 50% 0px;
                        }
                    }
                }

                &.b {
                    top: 79%;
                    left:87%;
                }

                &.c {
                    top: 34.5%;
                    left: 44.5%;
                }

                &.d {
                    top: 40%;
                    left:17%;

                }

                &.e {
                    top:52.5%;
                    left:63.5%;

                    @media only screen and (max-width:$tab) {
                        .flag_wrap {
                            transform: translate(-14px, calc(-100% - 12px)) rotate(45deg);

                        }
                    }
                }

                &.f {
                    top:47%;
                    left:57.5%;

                    @media only screen and (max-width:$tab) {
                        .flag_wrap {
                            transform: translate(-100%, 8px);
                            border-radius: 50% 0px 50% 50%;
                        }
                    }
                }

                &.g {
                    top: 33%;
                    left: 46%;

                    .flag_wrap {
                        transform: translate(4px, -100%);
                        border-radius: 50% 50% 50% 0px;
                    }
                }
            }
        }
    }


}

#locationcard {
    padding: 16px;
    background-color: $primary;
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .img_wrap {
        width: 100%;
        height: 200px;
        overflow: hidden;

        img {
            object-fit: cover;
            min-width: 100%;

        }
    }
}