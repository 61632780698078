@import "../../../scss/variables";

section.strategies {
    background-color: $primary;
    position: relative;
    z-index: 4;

    .content_wrap {
        .grid_wrap {
            display: grid;
            grid-template-columns: 1fr 0.8fr;
            gap: 56px;

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr;
                gap: 48px;
            }

            .text_wraper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 16px;
                align-self: flex-start;
                position: sticky;
                top: 150px;

                @media only screen and (max-width:$laptop) {
                    position: relative;
                    top: 0px;
                }

                h2 {
                    color:$white;
                    span {
                        color: $grey;
                    }
                }
            }

            .cards_list {
                display: flex;
                flex-direction: column;
                gap: 56px;

                @media only screen and (max-width:$laptop) {
                    gap: 32px;
                }


                .testimonial_card {
                    background-color:rgba($white, 0.05);
                    padding: 32px;
                    border: 1px solid $grey;
                    border-radius: 32px;
                    transition: all 0.6s ease-in-out;  
                    @media only screen and (max-width:$laptop){
                        gap:24px;
                        border-radius: 24px;
                    } 
                    @media only screen and (max-width:$tab){
                        gap:16px;
                        border-radius:16px;
                        padding:24px;
                    } 
                    @media only screen and (max-width:$mobile){
                        padding:16px;
                    }

                    svg {
                        font-size: 80px;
                        color:$white;

                        @media only screen and (max-width:$laptop) {
                            font-size: 64px;
                        }

                        @media only screen and (max-width:$tab) {
                            font-size: 48px;
                        }

                        @media only screen and (max-width:$mobile) {
                            font-size: 40px;
                        }
                    }

                    h3 {
                        margin-top: 300px;
                        color:$white;
                        @media only screen and (max-width:$laptop) {
                            margin-top: 150px;
                        }
                    }

                    p {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}