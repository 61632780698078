@import "../../../scss/variables";

section.blog_landing {
    background-color: $primary;

    .content_wrap {
        h2 {
            color: $white;
        }

        .bi_grid {
            display: grid;
            grid-template-columns: 1.8fr 1.2fr;
            gap: 32px;
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
            gap:24px;
            }

            .blog_card {
                border-radius: 16px;
                border: 1px solid rgba($secondary, 0.7);
                overflow: hidden;
                align-self: flex-start;

                .img_wrap {
                    img {
                        width: 100%;
                        border-radius: 16px;
                        filter: blur(5px);
                        opacity: 0.5;
                        transition: all 0.3s ease-in-out;
                    }
                }

                .text_wrap {
                    padding: 32px;
                    background-color: $primary;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 16px;
                    transition: all 0.3s ease-in-out;

                    @media only screen and (max-width:$desktop) {
                        padding: 24px;
                    }

                    @media only screen and (max-width:$tab) {
                        padding: 16px;
                    }

                    h3 {
                        color: $white;
                    }

                    p {
                        color: $white;
                    }

                    .category_line {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 16px;
                        flex-wrap: wrap;


                        .category {
                            padding: 16px;
                            border-radius: 32px;
                            background-color: $secondary;

                            p {
                                color: $primary;
                            }

                        }
                    }
                }

                &:hover {
                    .img_wrap {
                        img {

                            filter: blur(0px);
                            opacity: 1;
                        }
                    }

                    .text_wrap {
                        background-color: $lightOffDark;
                    }
                }
            }


        }
    }
}