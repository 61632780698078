@import "../../../scss/variables";

section.blog_content {
    background-color: $secondary;

    .content_wrap {
        max-width: 1000px;

        p.des {
            color: $primary;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid $primary;
        }

        p {
            margin-bottom: 24px;
            font-size:20px;
            line-height: 24px;
            span {
                color: $primary;
            }
        }

        h1, h2, h3, h4, h5 {
            margin-bottom: 24px;
            color: $primary;
        }

      

    }
}