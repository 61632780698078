@import "../../../scss/variables";
@import "../../../scss/mixin";

section.client_list {
    background-color: $primary;

    .content_wrap {
        h2 {
            color: $white;
        }

        .category_dd {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 16px;
            @media only screen and (max-width:$mobile){
                justify-content: flex-start;
                margin-top:8px;
                margin-top:24px;
            }

            .type_dd {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                position: relative;
                background-color: $offDark;
                padding: 8px 16px;
                width: 200px;
                border: 1px solid $grey;

                p {
                    @include font('DMSans-Medium', 18px, 24px, 500, $white);
                }

                span {
                    svg {
                        color: $white;
                        font-size: 18px;
                        transition: all 0.2s ease-in;
                    }

                    &.open {
                        transform: rotate(180deg);
                        transition: all 0.2s ease-in;
                    }

                    &.close {
                        transform: rotate(0deg);
                        transition: all 0.2s ease-in;
                    }
                }


                .dd_list {
                    width: 100%;
                    transform-origin: top center;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    position: absolute;
                    top: 44px;
                    right: 0px;
                    z-index: 9;
                    background-color: $offDark;
                    border: 1px solid $grey;
                    transition: all 0.2s ease-in-out;
                    max-height: 250px;
                    overflow: auto;

                    &.open {
                        transform: scaleY(1);
                    }

                    &.close {
                        transform: scaleY(0);
                    }


                    button {
                        width: 100%;
                        padding: 8px 16px;
                        border-style: none;
                        background-color: transparent;
                        @include font('DMSans-Medium', 18px, 24px, 500, $white);
                        transition: all 0.3s ease-in-out;

                        &:hover,
                        &.active {
                            background-color: $lightOffDark;
                        }
                    }
                }
            }
        }

        .client_grid {
            margin-top: 56px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 24px;

            @media only screen and (max-width:$desktop) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr 1fr ;
                gap: 16px;
                margin-top:24px;
            }
         

            .client_card {
                padding-bottom: 100%;
                position: relative;
                background-color: $offDark;
                border-radius: 8px;
                transform-origin: left center;
                overflow: hidden;



                .logo_wraper {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: grid;
                    place-items: center;
                    padding: 24px;
                    transform: scale(1);
                    transition: all 0.3s ease-in-out;

                    @media only screen and (max-width:$tab) {
                        padding: 8px;
                    }

                    @media only screen and (max-width:$mobile) {
                        padding: 0px;
                    }

                    &:hover {
                        transform: scale(1.05);
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;

                    }
                }
            }
        }
    }
}