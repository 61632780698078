@import "../../../scss/variables";

section.card_stack_sec {
    background-color: $primary;
    position: relative;

    @media only screen and (max-width:$tab) {
        display: none;
    }


    .content_wrap {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0px;


        h2 {
            color: $white;
            text-align: center;
            transform: translateY(50%);

            span {
                color: $grey;
            }
        }
    }


    .card_blog {
        position: sticky;
        bottom: 0px;
        overflow: hidden;
        width: 100%;

    }

    .card_screen {
        width: 100%;
        height: 90vh;
        display: grid;
        place-items: center;
        padding: 0px 32px;
        background-color: transparent;

        @media only screen and (max-width:$mobile) {
            padding: 0px;
        }


    }


    .card_box {
        border: 1px solid $grey;
        padding: 24px;
        border-radius: 16px;
        max-width: 500px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        min-height: 300px;
        background-color: $offDark;

        @media only screen and (max-width:$mobile) {
            min-height: 400px;
            padding: 16px;
        }

        svg {
            font-size: 28px;
            color: $white;
        }

        h4 {
            max-width: 100%;
            color: $white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }



}


section.tab_screen {
    display: none;

    @media only screen and (max-width:$tab) {
        display: block;
    }

    .content_wrap {
        h2 {
            color: $white;
            margin-bottom: 48px;
        }

        .tab_card_box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px;
            border: 1px solid $grey;
            padding: 24px;
            border-radius: 16px;
            width: 100%;
            margin-top: 16px;
            position: sticky;
            top: 148px;
            background-color: $offDark;

            @media only screen and (max-width:$mobile) {
                padding: 16px;
                top: 80px;
            }

            svg {
                font-size: 24px;
                color: $white;
            }

            h4 {
                max-width: 100%;
                color: $white;
            }
        }
    }

}