@import "../../../scss/variables";

section.case_detail{
    background-color: $primary; 
    position:sticky;
    top:68px;
    z-index:0;

    @media only screen and (max-width:$menubreak){
        top:56px;
    }
    .content_wrap{
        p{
            color:$white;
        }
        h1{
            color:$white;
        }
    }
}