@import "../../../scss/variables";
@import "../../../scss/mixin";

section.challenges {
    background-color: $primary;
    overflow: hidden;
    position: relative;
    z-index: 2;

    .content_wrap {
        padding-top: 120px;
        padding-bottom: 120px;

        @media only screen and (max-width:$laptop) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        h3 {
            color: $grey;
            margin-bottom: 24px;

            &.title {
                color: $white;
            }

            &.detail {
                @media only screen and (max-width:$tab) {
                    @include font('DMSans-Medium', 24px, 28px, 400, $grey);
                }

                @media only screen and (max-width:$mobile) {
                    @include font('DMSans-Medium', 20px, 24px, 400, $grey);
                }
                span{
                    color:$white;
                }
            }
        }


        .video_wrap {
            margin-top: 100px;

            video {
                width: 100%;
                border-radius: 24px;
            }

            img {
                width: 100%;
                border-radius: 24px;
            }
        }
    }
}