@import "../../../scss/variables";

.zero{
    .line{
        display:flex;
        justify-content: flex-end;
        align-items: center;

        span{
            display:inline-block;
            height:24px;
            width:24px;
            border-radius: 50%;
            background-color:transparent;
            @media only screen and (max-width:800px){
                height:16px;
                width:16px;
            }
            @media only screen and (max-width:550px){
                height:8px;
                width:8px;
            }
            &.filled{
                background-color:$grey;
                animation-name: animColor;
                animation-duration:6s;
                animation-iteration-count: infinite;
                &:nth-child(3n){
                    animation-delay: 2s;
                }
                &:nth-child(4n){
                    animation-delay: 5s;
                }
                &:nth-child(5n){
                    animation-delay: 4s;
                }
            }
          
        }
    }
   }

   @keyframes animColor {
  
    0%{
        background-color:$lightOffDark;
    }
    30%{
        background-color:$grey;
    }
    70%{
        background-color: $secondary;
    }
   }