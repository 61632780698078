@import "../../../scss/variables";

section.service_benefit_title {
    background-color: $primary;
    position: relative;
    z-index: 3;
    .content_wrap {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;

            h2 {
                color: $white;

                span {
                    color: $grey;
                }
            }

        }
    }
}

section.service_benefit_slider {
    background-color: $primary;
    ;
    padding-left: 0px;
    padding-right: 0px;

    .horizntal_slider {
        position: sticky;
        top: 70px;

        @media only screen and (max-width:$mobile) {
            display: none;
        }

        .sliders_tab_wrap {
            display: flex;
            justify-content: flex-start;
            align-self: center;
            gap: 24px;
            padding-left: 80px;
            padding-right: 80px;

            @media only screen and (max-width:$laptop) {
                padding-left: 56px;
                padding-right: 56px;
            }

            @media only screen and (max-width:$tab) {
                padding-left: 32px;
                padding-right: 32px;
            }


            button {
                border-style: none;
                background-color: transparent;
                padding: 8px 16px;
                position: relative;
                color: $white;
                color: $grey;
                transition: all 0.3s ease-in-out;

                @media only screen and (max-width:$tab) {
                    padding: 8px 8px;
                }


                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0%;
                    height: 1px;
                    background-color: $white;
                    transition: all 0.3s ease-in-out;
                }

                &.active,
                &:hover {
                    color: $white;

                    &:after {
                        width: 80%;
                    }
                }

            }
        }

        .tab_slider {
            width: 100%;
            overflow: hidden;

            @media only screen and (max-width:$laptop) {
                width: 100%;
            }

            .slick-slider {

                width: 80%;
                margin-left: auto;
                margin-right: auto;

                @media only screen and (max-width:$mobile) {
                    width: 100%;
                }

                button.slick-arrow {
                    display: none !important
                }

                .slick-list {
                    overflow: visible;

                    .slick-track {

                        .slick-slide {
                            @media only screen and (max-width:$mobile) {
                                padding: 0px 8px;
                            }

                          
                        }
                    }
                }
            }
        }
    }
}


section.mobile_sec {
    display: none;
    background-color: $primary;

    @media only screen and (max-width:$mobile) {
        display: block;
    }

    .content_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}