@import "../../../scss/variables";

section.home_blog_sec{
    background-color: $secondary;
    position: relative;
    z-index: 4;
    .content_wrap{
        .title{
            display:flex;
            justify-content: space-between;
            gap:16px;
            align-items: center;
            flex-wrap: wrap;
           h2{
            span{
                color:$grey;
            }
           }
        }
        .tri_sec{
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:24px;
            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr 1fr;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                gap:16px;
            }

            .blog_card{
                &:nth-child(3){                    
                @media only screen and (max-width:$laptop){
                    display:none;
                  }
                }
            }
            
            
        } 
    }
}