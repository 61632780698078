@import "../../scss/variables";


.icon_info_card {
    border: 1px solid rgba($grey, 0.5);
    background-color: $ternery;
    border-radius: 24px;
    transform: scale(0.90);
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
    padding: 80px;

    @media only screen and (max-width:$laptop) {
        padding: 56px;
    }

    @media only screen and (max-width:$tab) {
        padding: 32px;
    }



    &.active {
        transform: scale(1);
        opacity: 1;
    }

    svg {
        font-size: 48px;
        color: $primary;
    }

    h3 {
        color: $primary;
        margin: 156px 0px 16px;
    }

    &.dark{
        background-color:$offDark;
        svg {
            color: $white;
        }

        h3 {
            color: $white;
        }
    }

    @media only screen and (max-width:$mobile) {
        padding: 24px;
        transform: scale(1);
        opacity:1;
        h3 {         
            margin: 100px 0px 16px;
        }
    }
}