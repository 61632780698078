@import "../../../scss/variables";

section.data_analytics_process {
    background-color: $primary;
    position: relative;
    overflow: hidden;

    .v_tab_line {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 600px;
        background-color: #333;

    }



    .content_wrap {
        h2 {
            color: $white;
            text-align: center;
            background-color: $primary;
            position: relative;
            z-index: 2;
        }

        .process_list {
            .process_flap {
                background-color: $primary;
                border: 1px solid $lightOffDark;
                padding: 48px;
                border-radius: 24px;
                margin-top: 56px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                gap: 8px;
                overflow: hidden;

                @media only screen and (max-width:$laptop) {
                    padding: 32px;
                }

                @media only screen and (max-width:$tab) {
                    padding: 24px;
                    border-radius: 16px;
                }

                @media only screen and (max-width:$mobile) {
                    padding: 16px;

                }

                h3 {
                    color: $white;
                    position: relative;
                    z-index: 1;
                }

                p {
                    position: relative;
                    z-index: 1;
                }

                .flap {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 0%;
                    background-color: $offDark;
                    z-index: 0;
                    transition: all 0.3s ease-in-out;

                    &.active {
                        width: 100%;
                    }
                }
            }
        }
    }
}