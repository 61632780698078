@import "../../../scss/variables";

section.services_offerings {
background-color: $primary;


    .content_wrap {
        h2{
            color: $white;
            margin-bottom:56px;
        }
    }
}