@import "./mixin";
@import "./variables";


@font-face {
    font-family: 'NeueMontreal-Light';
    src: url('../assets/fonts/NeueMontreal-Light.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueMontreal-Regular';
    src: url('../assets/fonts/NeueMontreal-Regular.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueMontreal-Medium';
    src: url('../assets/fonts/NeueMontreal-Medium.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueMontreal-Bold';
    src: url('../assets/fonts/NeueMontreal-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}


// Inter

@font-face {
    font-family: 'Inter-Medium';
    src: url('../assets/fonts/Inter-Medium.woff') format('woff');
    font-style: normal;
    font-display: swap;
}



// Latest Updated Font

@font-face {
    font-family: 'DMSans-Medium';
    src: url('../assets/fonts/DMSans-Medium.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DMSans-Regular';
    src: url('../assets/fonts/DMSans-Regular.woff') format('woff');
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'DMSans-ExtraLight';
    src: url('../assets/fonts/DMSans-ExtraLight.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DMSans-Light';
    src: url('../assets/fonts/DMSans-Light.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DMSans-Black';
    src: url('../assets/fonts/DMSans-Black.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DMSans-Bold';
    src: url('../assets/fonts/DMSans-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DMSans-ExtraBold';
    src: url('../assets/fonts/DMSans-ExtraBold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}






@font-face {
    font-family: 'DMSans-SemiBold';
    src: url('../assets/fonts/DMSans-SemiBold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DMSans-Thin';
    src: url('../assets/fonts/DMSans-Thin.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

button {
    @include font('DMSans-Medium', 1.25rem, 24px, 500, $mainGrey);
}

p {
    margin: 0px;
    @include font('DMSans-Regular', 1.5rem, 1.2, 400, $grey);

    &.p_second {
        @include font('DMSans-Medium', 1.25rem, 24px, 500, $mainGrey);
    }

    &.p_big {
        @include font('DMSans-Medium', 2rem, 1.2, 500, $greySecondary);
    }
}

h1 {
    margin: 0px;
    @include font('DMSans-Regular', 6vw, 1.10, 400, $primary);
}

h2 {
    margin: 0px;
    @include font('DMSans-Regular', min(max(48px, 5.556vw), 80px), 1.05, 400, $primary);
}

h3 {
    margin: 0px;
    @include font('DMSans-Medium', min(max(30px, 2.5vw), 36px), 1.2, 400, $primary);
}

h4 {
    margin: 0px;
    @include font('DMSans-Medium', 32px, 36px, 400, $primary);
}

h5 {
    margin: 0px;
    @include font('DMSans-Medium', 22px, 26px, 500, $primary);
}

input {
    @include font('DMSans-Medium', 1.25rem, 24px, 500, $mainGrey);

    &:focus {
        box-shadow: none;
        outline: none;
    }
}


a {
    text-decoration: none;
    margin: 0px;
    @include font('DMSans-Medium', 18px, 20px, 500, $primary);
}

@media only screen and (max-width:$tab) {
    h1 {
        @include font('DMSans-Regular', 56px, 60px, 400, $primary);
    }

    p {
        @include font('DMSans-Regular', 20px, 24px, 400, $grey);
    }
     input{
        @include font('DMSans-Medium', 1.3rem, 1.4, 500, $mainGrey);
     }
}


@media only screen and (max-width:$mobile) {
    h1 {
        @include font('DMSans-Regular', 48px, 52px, 400, $primary);
    }

}