@import "../../scss/variables";

section.error_page {
    background-color:$primary;

    .content_wrap {
        .number_wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;     
        }
        .error_content{
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top:48px;
            
            h1 {
                color: $secondary;
                text-align: center;
            }
    
            h3 {
                color: $white;
                text-align: center;
            }
            button{
                margin-top:24px;
            }
        }
        
    }
}