@import "../../../../scss/variables";


.blog_card {
    border: 1px solid rgba($grey, 0.5);
    border-radius: 16px;
    cursor: pointer;
    transform:scale(1);
    transition:all 0.3s ease-in-out;
&:hover{
    transform:scale(1.01);
}

    .img_wrap {
        border-radius: 16px;
        overflow: hidden;

        img {
            width: 100%;
            height:300px;
            object-fit: cover;
        }
    }

    .text {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;

        p.des{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        h4, p.des{
            display: -webkit-box;
            -webkit-line-clamp:2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }

        .category_line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;

            .category {
                padding:12px 18px;
                border-radius: 32px;
                background-color: $primary;

                p {
                    color: $white;
                }

            }
        }
    }
}