@import "../../../scss/variables";

section.about_casestudy {
    background-color: $primary;
    position:relative;
    z-index:3;
    .content_wrap {
        .title {
            p {
                max-width: 1000px;
                width: 100%;
                color: $white;
            }

            h2 {
                color: $white;
                margin-bottom: 16px;
            }
        }

        .portfolio_slider {
            margin-top: 56px;

            .slick-slider {
                button.slick-arrow {
                    display: none !important;
                }

                .slick-list {
                    .slick-track {
                        .slick-slide {                           

                            .portfolio_card {
                                border-radius: 16px;
                                overflow: hidden;
                                border: 1px solid rgba($grey, 0.5);
                                opacity: 1;
                                transform: scale(1);
                                transition: all 0.6s ease-in;

                                &.blure {
                                    opacity: 0.5;
                                    transform: scale(0.9);
                                    cursor:pointer;
                                }

                                .video_screen {
                                    width: 100%;
                                    background-color: $ternery;
                                        padding: 0px;
                                    overflow: hidden;
                                    display:grid;
                                    place-items: center;
                                    video {
                                        width: 100%;
                                        height:100%;     
                                        object-fit:cover;
                                    }
                                }
                                .name_wrap {
                                    padding: 24px;
                                    background-color: $offDark;

                                  h3{
                                    color:$white;
                                  }
                                }

                                &:hover {
                                    .name_wrap {
                                        background-color: $lightOffDark;
                                    }
                                }
                            }
                        }
                    }
                }
            }



        }

        .btn_wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap:16px;
            margin-top: 32px;

            .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                button {
                    height: 84px;
                    width: 84px;
                    border-radius: 50%;
                    border: 1px solid rgba($grey, 0.5);
                    display: grid;
                    place-items: center;
                    background-color: $offDark;
                    transition: all 0.3s ease-in-out;
                    @media only screen and (max-width:$mobile){
                        height:60px;
                        width:60px;
                    }

                    &:hover {
                        background-color: $lightOffDark;
                    }
                }
            }

            svg {
                font-size: 24px;
                color: $secondary;
            }
        }
    }
}