@import "../../scss/variables";
@import "../../scss/mixin";

p.error {
    margin-top: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    @include font('DMSans-Regular', 16px, 22px, 400, rgb(211, 25, 25));

    svg {
        font-size: 12px;
    }
}