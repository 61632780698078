@import "../../../scss/variables";

section.portfolio_sec {
    background-color: $primary;
    position: relative;
    z-index: 3;

    .content_wrap {
        .title {
            p {
                max-width: 1000px;
                width: 100%;
                color: $white;
            }

            h2 {
                color: $white;
                margin-bottom: 16px;
            }
        }

        .portfolio_slider {
            margin-top: 56px;

            .slick-slider {
                button.slick-arrow {
                    display: none !important;
                }

                .slick-list {
                    .slick-track {
                        .slick-slide {

                           
                        }
                    }
                }
            }



        }

        .btn_wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 32px;

            .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                button {
                    height: 84px;
                    width: 84px;
                    border-radius: 50%;
                    border: 1px solid rgba($grey, 0.5);
                    display: grid;
                    place-items: center;
                    background-color: $offDark;
                    transition: all 0.3s ease-in-out;

                    @media only screen and (max-width:$mobile) {
                        height: 60px;
                        width: 60px;
                    }

                    &:hover {
                        background-color: $lightOffDark;
                    }
                }
            }

            svg {
                font-size: 24px;
                color: $secondary;
            }
        }
    }
}